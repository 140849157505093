<template>
  <v-container fluid grid-list-lg class="pa-0">
     <SearchHeader :onAction="doSearch"></SearchHeader>

    <v-data-table :headers="headers" :items="sellers" :items-per-page="100" :search="search" class="elevation-1 ma-5">
      <template v-slot:item.action="{ item }">
        <v-icon @click="editItem(item)">
          mdi-account-edit
        </v-icon>
        <v-icon @click="deleteItem(item)">
          mdi-account-remove
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("common.list.actions") }}
      </template>
    </v-data-table>

    <CfgPanel ref="cfgpanel" :onSave="save" :title="$t('sellers.edit.title')" :tabIndex="8003">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" xs="12">
              <v-text-field
                v-model="seller.name"
                :rules="rules.required"
                :label="$t('sellers.list.name')"
                required
                ref="firstfield"
                :tabIndex="8002"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.organisation" :label="$t('sellers.list.organisation')" :tabIndex="2"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.email" :rules="rules.email" :label="$t('sellers.list.email')" :tabIndex="3"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.phone" :label="$t('sellers.list.phone')" :tabIndex="4"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.phone2" :label="$t('sellers.list.phone2')" :tabIndex="4"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.mobile" :label="$t('sellers.list.mobile')" :tabIndex="4"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.bankname" :label="$t('sellers.edit.bankname')" :tabIndex="5"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.iban" :label="$t('sellers.edit.iban')" :tabIndex="5"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <v-text-field v-model="seller.bic" :label="$t('sellers.edit.bic')" :tabIndex="5"></v-text-field>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("sellers.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </CfgPanel>
  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

import CfgPanel from "@/components/_common/cfgpanel";
import SearchHeader from "@/components/_common/searchheader";

export default {
  mixins: [formatMixin, formMixin, listMixin],

  data() {
    return {
      valid: false,
      show1: false,
      search: "",
      headers: [
        { text: this.$i18n.t("sellers.list.name"), align: "left", value: "name" },
        { text: this.$i18n.t("sellers.list.organisation"), align: "left", value: "organisation" },
        { text: this.$i18n.t("sellers.list.email"), align: "left", value: "email" },
        { text: this.$i18n.t("sellers.list.phone"), align: "left", value: "phone" },
        { text: this.$i18n.t("sellers.list.phone2"), align: "left", value: "phone2" },
        { text: this.$i18n.t("sellers.list.mobile"), align: "left", value: "mobile" },
        { text: this.$i18n.t("common.list.actions"), value: "action", sortable: false }
      ],
      sellers: [],
      editedIndex: -1,
      seller: {}
    };
  },

  components: {
    CfgPanel,
    SearchHeader
  },

  created() {
    this.loadSellers();
  },

  methods: {
    doSearch: function(val) {
      this.search = val
    },

    loadSellers: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/sellers`)
        .then(response => {
          this.sellers = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch(e => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    editItem: function(item) {
      this.$refs.cfgpanel.show();
      this.$refs.firstfield.focus();
      var u = null;
      this.editedIndex = -1;
      this.seller = {};

      if (item) {
        u = this.sellers.find(seller => {
          return seller.id === item.id;
        });
        this.editedIndex = this.sellers.indexOf(u);
        if (this.editedIndex >= 0) {
          this.seller = Object.assign({}, u);
        }
      }
    },

    deleteItem: function(item) {
      if (confirm("Are you sure you want to delete this seller?")) {
        axios
          .delete(`/api/sellers/${item.id}`)
          .then(() => {
            this.loadSellers();
          })
          .catch(e => {
            console.log(e);
          });
      }
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let u = { ...this.seller };

        axios
          .post(`/api/sellers/${u.id || ""}`, u)
          .then(msg => {
            this.updateItemList(this.sellers, msg.data);
            this.$refs.cfgpanel.hide();
          })
          .catch(e => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  }
};
</script>
